const messages = {
  title: 'Admin',
  companyName: 'AJ Homes',
  toLet: 'TO LET',
  forSale: 'FOR SALE',
  about: 'ABOUT',
  residentialLettings: 'RESIDENTIAL LETTINGS',
  studentLettings: 'STUDENT LETTINGS',
  contact: 'CONTACT US'
};

export default messages;
